import React, { useReducer } from "react";
import { createContext, useContextSelector } from "use-context-selector";

interface LayoutContextObjectParams {
  showPageLoading: boolean;
  headerTitle: string;
  pageTitle: string;
  showFilter: string;
  showServerError: boolean;
  useAppliedFilter: boolean;
  headerEnableAll: boolean;
  errorRedirect: boolean;
  errorStatus: any;
  scrollHeight: boolean;
}

const initLayoutParams: LayoutContextObjectParams = {
  showPageLoading: true,
  headerTitle: "",
  pageTitle: "",
  showFilter: "",
  showServerError: false,
  useAppliedFilter: false,
  headerEnableAll: true,
  errorRedirect: false,
  errorStatus: "",
  scrollHeight: false,
};

interface LayoutContextObjectData extends LayoutContextObjectParams {
  dispatchLayoutObjects: React.Dispatch<any>;
}

export const LayoutContext = createContext({} as LayoutContextObjectData);

const layoutReducer = (state = initLayoutParams, action: any) => ({
  ...state,
  ...action.payload,
});

export const LayoutProvider: React.FC = (props) => {
  const [layoutObjects, dispatchLayoutObjects] = useReducer(
    layoutReducer,
    initLayoutParams
  );
  const context: LayoutContextObjectData = {
    ...layoutObjects,
    dispatchLayoutObjects,
  };
  return (
    <LayoutContext.Provider value={context}>
      {props.children}
    </LayoutContext.Provider>
  );
};

export const useLayoutDispatch = () => {
  return useContextSelector(LayoutContext, (v) => v.dispatchLayoutObjects);
};
export const useLayoutSelector = (fn: any) =>
  useContextSelector(LayoutContext, fn) as any;
