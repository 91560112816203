import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import "./i18n";
import UserService from "./services/UserService";

// ReactDOM.render(
//   <React.StrictMode>
//     <App />
//   </React.StrictMode>,
//   document.getElementById("root")
// );
if (process.env.REACT_APP_AUTHORIZATION != undefined && process.env.REACT_APP_AUTHORIZATION == 'false') {
    ReactDOM.render(<App />, document.getElementById('root'))
} else {
    UserService.initKeycloak(() => ReactDOM.render(<App />, document.getElementById('root')));
}
// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
