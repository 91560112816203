import axios from "axios";
import UserService from "../services/UserService";

axios.defaults.baseURL = process.env.REACT_APP_API_URL;
axios.interceptors.request.use(
	(req: any) => {
		let headers;
		if (req.url.includes("id.clevertap")) {
			const accessToken = UserService.getAccessToken();
			headers = { Authorization: accessToken ? "Bearer " + accessToken : "" };
		} else {
			const tokenDetails = UserService.getToken();
			headers = { Authorization: tokenDetails ? tokenDetails : "" };
		}
		req.headers = {
			...req.headers,
			...headers,
		};
		return req;
	},
	(err) => {
		console.log(err);
	}
);

axios.interceptors.response.use(
	(res: any) => {
		return res;
	},
	(error) => {
		if (error.response && error.response.data) {
			return Promise.reject(error.response.data);
		}
		return Promise.reject(error.message);
	}
);

// axios.defaults.baseURL = process.env.REACT_APP_API_URL;
// axios.interceptors.request.use(
// 	(req: any) => {
// 		if(req.url.includes('id.clevertap')) {
// 			const accessToken = UserService.getAccessToken();
// 			req.headers = {Authorization: accessToken ? 'Bearer ' + accessToken : ''}
// 		}else {
// 			const tokenDetails = UserService.getToken();
// 			req.headers = { Authorization: tokenDetails ? tokenDetails : "" };
// 		}

// 		return req;
// 	},
// 	(err) => {
// 		console.log(err);
// 	}
// );
