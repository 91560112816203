import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import Backend from "i18next-http-backend";
import LanguageDetector from "i18next-browser-languagedetector";
import XHR from "i18next-http-backend";
import translationEN from "./assets/locales/language_en.json";
import translationFR from "./assets/locales/language_fr.json";

const fallbackLng = ["en"];
const availableLanguages = ["en", "fr"];
const resources = {
	en: {
		translation: translationEN,
	},
	fr: {
		translation: translationFR,
	},
};
const options = {
	order: ["querystring", "navigator"],
	lookupQuerystring: "lng",
};
i18n.use(XHR)
	.use(Backend)
	.use(LanguageDetector)
	.use(initReactI18next)
	.init({
		resources,
		//   lng: "en",
		fallbackLng,
		detection: options,
		supportedLngs: ["en", "fr"],
		debug: false,

		whitelist: availableLanguages,

		interpolation: {
			escapeValue: false,
		},
	});

export default i18n;
