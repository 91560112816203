import React from "react";
import {
  LoadingOutlined,
} from '@ant-design/icons';

export const LoadingOverlayComponent: React.FC = () => {
  return (
    <div className="loader-overlay">
      <div className="app-loading">
        <LoadingOutlined spin style={{ fontSize: '10rem'}} />
      </div>
    </div>
  );
};
