import React, { useEffect, useRef, useState } from "react";
import { useContextSelector } from "use-context-selector";
import { FilterContext } from "../filters/filterContext";
import { useLayoutDispatch } from "../layout-full-page/layoutContext";
import { IFilterValue } from "../models/filter.model";

export const useCustomLayoutDispatch = () => {
	const layoutDispatch = useLayoutDispatch();
	return (data: any) => {
		layoutDispatch({ type: "add", payload: data });
	};
};

export const useMountEffect = (fun: any) => useEffect(fun, []);

export const usePrevious = (value: any, defaultVal?: any) => {
  const ref = useRef<any>(defaultVal);
  useEffect(() => {
    ref.current = value;
  }, [value]);
  return ref.current;
};

export const useFilterPartial = (type: string, applied?: boolean) => {
	const filterObjects = useContextSelector(FilterContext, (v) => v.filterObjects);
	const appliedFilterObjects = useContextSelector(FilterContext, (v) => v.appliedFilterObjects);
	const prev: any = usePrevious({ filterObjects, appliedFilterObjects });
	const [filterPartial, setFilterPartial] = useState<IFilterValue[]>([]);

	const checkTwoArrays = (A: string[], B: string[]): boolean => {
		if (A.length === 0 && B.length === 0) return false;
		if (A.length !== B.length) return true;
		return !A.every((a) => B.indexOf(a) !== -1);
	};

	useEffect(() => {
		const targetFilterObjects = applied ? appliedFilterObjects : filterObjects;
		const targetPrevFilterObjects = prev ? (applied ? prev.appliedFilterObjects : prev.filterObjects) : [];
		const filterIds = targetFilterObjects?.filter((x) => x.type === type).map((x) => x.id);
		const prevFiltersIds = targetPrevFilterObjects.filter((x: any) => x.type === type).map((x: any) => x.id);
		if (checkTwoArrays(filterIds, prevFiltersIds)) {
			setFilterPartial(targetFilterObjects.filter((x) => x.type === type));
		}
	}, [filterObjects, appliedFilterObjects]);

	return filterPartial;
};

export const useInitPage = (headerTitle: string, showFilter: string, pageTitle: string = "", browserTabTitle: string = "", tabTitle: string = "", loading = true, filterClear = true): void => {
	const layoutDispatch = useCustomLayoutDispatch();
	  const dispatchAppliedFilterObjects = useContextSelector(
	    FilterContext,
	    (v) => v.dispatchAppliedFilterObjects
	  );

	useEffect(() => {
		if (browserTabTitle) document.title = browserTabTitle;
		layoutDispatch({
			headerTitle: headerTitle,
			pageTitle: pageTitle,
			showFilter: showFilter,
			showPageLoading: loading,
			tabTitle: tabTitle,
		});
		return () => {
			layoutDispatch({ errorStatus: undefined });
			if (filterClear) {
				layoutDispatch({ useAppliedFilter: false });
				dispatchAppliedFilterObjects({ type: "init", payload: [] });
			}
		};
	}, []);
};
